import request from "@/utils/request";

/**
 * 查看采集比对任务列表
 * @param {number} page 分页
 * @param {number} size 显示条数
 * @param {object} data 请求参数
 */
export async function queryAllTask(page, size, data) {
  return await request({
    url: "/sys/report/queryAllTask?currentPage=" + page + "&pageSize=" + size,
    method: "post",
    data,
  });
}
/**
 * 查询学生信息
 * @param {number} page 分页
 * @param {number} size 显示条数
 * @param {object} data 请求参数
 */
export async function queryEducationalsDetailByTaskId(page, size, data) {
  return await request({
    url:
      "/sys/report/queryEducationalsDetailByTaskId?page=" +
      page +
      "&size=" +
      size,
    method: "post",
    data,
  });
}
/**
 * 查看比对详情
 * @param {number} page 分页
 * @param {number} size 显示条数
 * @param {object} data 请求参数
 */
export async function queryReprotdataByTaskId(page, size, data) {
  return await request({
    url:
      "/sys/report/queryReprotdataByTaskId?currentPage=" +
      page +
      "&pageSize=" +
      size,
    method: "post",
    data,
  });
}
/**
 * 查询导入进度
 * @param {number} page 分页
 * @param {number} size 显示条数
 * @param {object} data 请求参数
 */
export async function importUploadList(taskId) {
  return await request({
    url: "/sys/report/uploadList?taskId=" + taskId,
    method: "post",
  });
}
/**
 * 生成比对报告任务
 * @param {number} page 分页
 * @param {number} size 显示条数
 * @param {object} data 请求参数
 */
export async function exportReportTaskList(taskId) {
  return await request({
    url: "/sys/report/reportTaskList?taskId=" + taskId,
    method: "post",
  });
}
/**
 *  批量上传刷卡
 * @param {*} data
 */
export async function uploadCardFile(data) {
  return await request({
    url: "/sys/report/uploadCardFile",
    method: "post",
    data,
  });
}
/**
 * 上传入学照
 * @param {*} data
 */
export async function uploadAdmissionFile(data) {
  return await request({
    url: "/sys/report/uploadAdmissionFile",
    method: "post",
    data,
  });
}
/**
 * 上传身份证正反照
 * @param {*} data
 */
export async function uploadTwoCardFile(data) {
  return await request({
    url: "/sys/report/uploadTwoCardFile",
    method: "post",
    data,
  });
}
/**
 * 批量上传比对结果明细
 * @param {*} data
 */
export async function uploadReportDataFileAsyn(data) {
  return await request({
    url: "/sys/report/uploadReportDataFile",
    method: "post",
    data,
  });
}
/**
 * 添加生成比对报告任务
 * @param {*} data
 */
export async function createReportTask(id) {
  return await request({
    url: "/sys/report/createReportTask?taskId=" + id,
    method: "post",
  });
}
/**
 * 打包报告下载
 * @param {*} data
 */
export async function downloadReportAsync(id) {
  return await request({
    url: "/sys/report/downloadReport?id=" + id,
    method: "get",
  });
}
/**
 * 导出未上传刷卡信息学生
 * @param {*} data
 */
export async function exportNotUploadCardData(id) {
  return await request({
    url: "/file/exportNotUploadCardData/" + id,
    method: "get",
  });
}
/**
 * 导出未上传入学照学生名单
 * @param {*} data
 */
export async function exportNotUploadPhotoData(id) {
  return await request({
    url: "/file/exportNotUploadPhotoData/" + id,
    method: "get",
  });
}
