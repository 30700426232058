<template>
  <div class="page-content-flex">
    <!-- 搜索功能 -->
    <query-from ref="search" @funcSearchResult="funcSearchResult" />
    <!-- 搜索功能 -->
    <!-- 列表 -->
    <div class="page-table">
      <el-table :data="dataList" v-loading="tableLoading" stripe border height="100%" style="width: 100%"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }">
        <template #empty>
          <p>{{ tableLoading == true ? "数据加载中" : "暂无数据" }}</p>
        </template>
        <el-table-column prop="title" label="任务名称" width="220"></el-table-column>
        <el-table-column prop="cjtime" label="采集时间" width="200">
          <template slot-scope="scope">
            {{ formDateFormat(scope.row.beginTime) }} -
            {{ formDateFormat(scope.row.endTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="collectCount" label="采集人数" width="100" align="center"></el-table-column>
        <el-table-column prop="photographer" label="摄影师" width="200"></el-table-column>
        <el-table-column prop="photoToPhotoList" label="录取照&入学采集照" width="200">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.photoToPhotoList" :key="item.id">
              {{ index ? "，" : "" }}{{ item.comparisonName }}：{{
              item.comparisonCount
              }}人
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="photoToNationList" label="录取照&国家人口库" width="200">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.photoToNationList" :key="item.id">
              {{ index ? "，" : "" }}{{ item.comparisonName }}：{{
              item.comparisonCount
              }}人
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="newPhotoNationList" label="入学采集照&国家人口库" width="200">
          <template slot-scope="scope">
            <span v-for="(item, index) in scope.row.newPhotoNationList" :key="item.id">
              {{ index ? "，" : "" }}{{ item.comparisonName }}：{{
              item.comparisonCount
              }}人
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="cjresult" label="导入记录" align="center" width="100">
          <template slot-scope="scope">
            <el-button @click="headerExportInSchedule(scope.row.id, 'view')" type="text">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" width="200"></el-table-column>
        <el-table-column label="操作" width="353" align="right" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="permission.updateCardButton" type="text"
              @click="headerUploadCard(scope.row.id, 'cart')">上传刷卡信息</el-button>
            <el-button v-if="permission.uploadAdmitPhotoButton" type="text"
              @click="headerUploadCard(scope.row.id, 'photo')">上传录取照</el-button>
            <el-button v-if="permission.uploadCardInfoButton" type="text"
              @click="headerUploadCard(scope.row.id, 'sfz')">上传身份证信息</el-button>
            <el-button v-if="permission.uploadReportButton" type="text"
              @click="headerUploadCard(scope.row.id, 'report')">上传比对结果
            </el-button>
            <el-button v-if="permission.studentButton" type="text" @click="handleStudentInfo(scope.row.id)">学生信息
            </el-button>
            <el-button v-if="permission.compareDetailsButton" type="text" @click="handleDetails(scope.row.id)">查看比对详情
            </el-button>
            <el-button v-if="permission.produceCompareReportButton" type="text"
              @click="handleCreateReportTask(scope.row.id)">生成比对报告
            </el-button>
            <el-button v-if="permission.downCompareReportButton" type="text"
              @click="headerExportInSchedule(scope.row.id, 'download')">批量下载比对报告
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 -->
    <!-- 分页 -->
    <div class="page-footer">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="pageConfig.currentPage" :page-sizes="pageConfig.limits" :page-size="pageConfig.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageConfig.total" />
    </div>
    <!-- 分页 -->
    <student-info ref="stuInfo" :exportStudentButton="permission.exportStudentButton"
      :exportInStudentButton="permission.exportInStudentButton" />
    <export-in-schedule ref="exportSchedule" :title="exportSchedule.title" :download="exportSchedule.download" />
    <exportDownSchedule ref="exportDownSchedule" :title="exportSchedule.title" :download="exportSchedule.download" />
    <compare-details ref="details" />
    <!-- 上传学生信息/成教点 -->
    <el-dialog :title="UplodTitle" :visible.sync="stuUplodShow" width="500px" :close-on-click-modal="false"
      :close-on-press-escape="false">
      <el-upload class="upload-demo" ref="uploadImport" :multiple="false" action="" :limit="1"
        :on-change="handleUploadChangeAsync" :file-list="[]" :accept="uploadExt" :auto-upload="false"
        :show-file-list="false" v-if="uploadType === 'report'">
        <el-button slot="trigger" size="small" type="primary" :loading="stuUpdateLoading"
          :disabled="stuUpdateLoading">{{ stuUpdateLoading ? "文件上传中..." : "选取文件" }}
        </el-button>
      </el-upload>
      <el-upload class="upload-demo" ref="uploadImport" :multiple="false" action="" :limit="1"
        :on-change="handleUploadChange" :file-list="[]" :accept="uploadExt" :auto-upload="false" :show-file-list="false"
        v-else>
        <el-button slot="trigger" size="small" type="primary" :loading="stuUpdateLoading"
          :disabled="stuUpdateLoading">{{ stuUpdateLoading ? "文件上传中..." : "选取文件" }}
        </el-button>
      </el-upload>
    </el-dialog>
  </div>
</template>
<script>
  import queryFrom from "../extend/queryFrom.vue";
  import studentInfo from "./extend/studentInfo.vue";
  import exportInSchedule from "../extend/exportInSchedule.vue";
  import exportDownSchedule from "./extend/exportInSchedule.vue";
  import compareDetails from "./extend/compareDetails.vue";
  import { queryPageButton } from "@/api/permission";
  import { queryAllTask, createReportTask } from "@/api/report";
  import { dateFormat } from "@/utils/date";
  import { getFileMd5, mergeFile, chunkFileList } from "@/utils/mergeFile";
  import {
    uploadCardFile,
    uploadAdmissionFile,
    uploadReportDataFileAsyn,
  } from "@/api/report";
  import { uploadTwoCardFile } from "@/api/report";
  export default {
    name: "compareServeTaskList",
    components: {
      queryFrom,
      studentInfo,
      exportInSchedule,
      compareDetails,
      exportDownSchedule,
    },
    data() {
      return {
        dataList: [],
        tableLoading: false,
        searchObj: {
          title: "", //任务标题
          schoolName: "", //学校名称
          stime: "", //采集时间-开始时间
          etime: "", //采集时间-结束时间
        },
        pageConfig: {
          currentPage: 1,
          pageSize: 10,
          total: 0,
          limits: [10, 20, 30, 40, 50, 100],
        },
        permission: {
          authId: 235, //比对任务管理
          searchButton: false, //筛选查询
          updateCardButton: false, //上传刷卡信息
          studentButton: false, //学生信息
          exportStudentButton: false, //导出未上传刷卡学生信息
          exportInStudentButton: false, //导出未上传入学照学生
          uploadAdmitPhotoButton: false, //上传录取照
          uploadCardInfoButton: false, //上传录取照
          uploadReportButton: false, //上传比对结果
          compareDetailsButton: false, //查看对比详情
          produceCompareReportButton: false, //生成比对报告
          downCompareReportButton: false, //批量下载比对报告
        }, //权限
        exportSchedule: {
          title: "导入进度",
          download: false, //
        },
        stuUpdateLoading: false,
        stuUplodShow: false,
        UplodTitle: "",
        comparisonId: "",
        uploadType: "cart",
        uploadExt: ".rar,.zip",
      };
    },
    methods: {
      //显示分布条数
      handleSizeChange(val) {
        this.pageConfig.pageSize = val;
        // this.execFrom();
        this.funcQueryAllTask();
      },
      //选择分页
      handleCurrentChange(val) {
        console.debug("Current page", this.pageConfig)
        this.pageConfig.currentPage = val;
        // this.execFrom();
        
        this.funcQueryAllTask();
      },
      formDateFormat(dateValue) {
        if (dateValue != null) {
          return dateFormat("YYYY-mm-dd", new Date(dateValue));
        }
      },
      /**
       * 搜索结果返回
       */
      funcSearchResult(params = {}) {
        this.searchObj.title = params.schoolName;
        this.searchObj.taskName = params.title;
        this.searchObj.etime = params.etime;
        this.searchObj.stime = params.stime;
        this.pageConfig.currentPage = 1;
        this.funcQueryAllTask();
      },
      execFrom() { },
      //查询学生信息
      handleStudentInfo(id) {
        if (this.$refs.stuInfo != undefined) {
          this.$refs.stuInfo.initData(id);
        }
      },
      //查看导入进度
      headerExportInSchedule(id, mod = "view") {
        if (mod == "view") {
          this.exportSchedule = {
            title: "导入进度",
            download: false,
          };
          if (this.$refs.exportSchedule != undefined) {
            this.$refs.exportSchedule.initData(id);
          }
        } else {
          this.exportSchedule = {
            title: "批量下载比对报告",
            download: true,
          };
          if (this.$refs.exportDownSchedule != undefined) {
            this.$refs.exportDownSchedule.initData(id);
          }
        }
      },
      handleCreateReportTask(id) {
        this.$confirm("您确定要生成比对报告?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            createReportTask(id)
              .then((resp) => {
                if (resp.code == 0) {
                  this.$notify({
                    title: "操作成功",
                    message: resp.message,
                    type: "success",
                  });
                  this.currentPage = 1;
                  this.query();
                } else {
                  this.$notify({
                    title: "警告",
                    message: resp.message,
                    type: "warning",
                  });
                }
              })
              .catch(() => { });
          })
          .catch(() => { });
      },
      //查看比对详情
      handleDetails(id) {
        if (this.$refs.details != undefined) {
          this.$refs.details.initData(id);
        }
      },
      /**
       * 上传刷卡信息、上传录取照
       * @param {*} id 任务ID
       * @param {*} mod 请求类型，cart:上传刷卡信息，photo:上传录取照
       */
      headerUploadCard(id, mod = "cart") {
        switch (mod) {
          case "cart":
            this.UplodTitle = "上传刷卡信息";
            this.uploadExt = ".rar,.zip";
            break;
          case "sfz":
            this.UplodTitle = "上传身份证信息";
            this.uploadExt = ".rar,.zip";
            break;
          case "photo":
            this.UplodTitle = "上传录取照";
            this.uploadExt = ".rar,.zip";
            break;
          case "report":
            this.UplodTitle = "上传比对结果";
            this.uploadExt = ".xls,.xlsx";
            break;
        }
        this.uploadType = mod;
        this.comparisonId = id;
        this.stuUplodShow = true;
        this.stuUpdateLoading = false;
      },
      //上传刷卡与录取照
      async handleUploadChange(file) {
        const chunkSize = 5 * 1024 * 1024;
        //1.获取hash唯一文件标识
        const fileMd5 = await getFileMd5(file.raw, chunkSize);
        // console.info(fileMd5);
        this.stuUpdateLoading = true;
        //2.文件切片信息
        const fileChunk = await chunkFileList(file.raw, fileMd5, chunkSize);
        console.info(fileChunk);
        //3.合并文件
        const merge = await mergeFile(
          fileChunk.chunkCount,
          file.raw.name,
          fileMd5,
          file.raw.size,
          file.raw.type,
          this.comparisonId
        );
        console.info("merge", merge);
        const params = {
          fileRealName: file.raw.name,
          fileName: merge.fileName,
          taskId: this.comparisonId,
        };
        console.info("params", params);
        //4.上传刷卡信息
        let resp = null;
        if (this.uploadType == "cart") {
          resp = await uploadCardFile(params);
        } else if (this.uploadType == "photo") {
          resp = await uploadAdmissionFile(params);
        } else if (this.uploadType == "sfz") {
          resp = await uploadTwoCardFile(params);
        }
        if (resp.code == 0) {
          this.stuUplodShow = false;
          this.stuUpdateLoading = false;
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "success",
            showClose: false,
          });
        } else {
          this.$notify({
            title: "温馨提示",
            message: resp.message,
            type: "error",
            showClose: false,
          });
          this.stuUpdateLoading = false;
        }
      },
      //上传比对结果
      async handleUploadChangeAsync(file) {
        let fdParams = new FormData();
        fdParams.append("file", file.raw);
        fdParams.append("taskId", this.comparisonId);
        this.stuUpdateLoading = true;
        const res = await uploadReportDataFileAsyn(fdParams);
        if (res.code == 0) {
          this.stuUplodShow = false;
          this.stuUpdateLoading = false;
          this.$notify({
            title: "温馨提示",
            message: res.message,
            type: "success",
            showClose: false,
          });
        } else {
          this.$notify({
            title: "警告",
            message: res.message,
            type: "warning",
            showClose: false,
          });
          this.stuUplodShow = false;
          this.stuUpdateLoading = false;
        }
      },
      //获取按钮权限
      async funcQueryButton() {
        const result = await queryPageButton(this.permission.authId);
        const permission = this.permission;
        for (const button of result.data) {
          if (button.code === "search") {
            permission.searchButton = true;
          }
          if (button.code === "updateCard") {
            permission.updateCardButton = true;
          }
          if (button.code === "student") {
            permission.studentButton = true;
          }
          if (button.code === "exportStudent") {
            permission.exportStudentButton = true;
          }
          if (button.code === "exportInStudent") {
            permission.exportInStudentButton = true;
          }
          if (button.code === "uploadAdmitPhoto") {
            permission.uploadAdmitPhotoButton = true;
          }
          if (button.code === "uploadCardInfo") {
            permission.uploadCardInfoButton = true;
          }
          if (button.code === "uploadReport") {
            permission.uploadReportButton = true;
          }
          if (button.code === "compareDetails") {
            permission.compareDetailsButton = true;
          }
          if (button.code === "produceCompareReport") {
            permission.produceCompareReportButton = true;
          }
          if (button.code === "downCompareReport") {
            permission.downCompareReportButton = true;
          }
        }
      },
      //
      async funcQueryAllTask() {
        const { currentPage, pageSize } = this.pageConfig;
        this.tableLoading = true;
        try {
          const result = await queryAllTask(currentPage, pageSize, this.searchObj);
          this.tableLoading = false;
          // load.close();
          if (result.code == 0) {
            this.dataList = result.data.content;
            this.pageConfig.total = result.data.totalElements;
          }
        } catch {
          this.tableLoading = false;
        }
      },
    },
    mounted() {
      this.funcQueryButton();
      this.funcQueryAllTask();
    },
  };
</script>